import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, filter, map, take } from 'rxjs';
import { AuthService } from '../pages/login/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard {
  constructor(private authService: AuthService, private router: Router) { }
  
  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        if (isAuthenticated) {   
          this.router.navigateByUrl('/dashboard')       
          return false;
        } else {          
          return true;
        }
      })
    );
  }
  
}
