import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  private recalculateTrigger = new BehaviorSubject(false);
  shouldRecalculate = this.recalculateTrigger.asObservable();

  constructor() { }

  recalculate(recalculate: boolean) {
    this.recalculateTrigger.next(recalculate)
  }
}
