<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu *ngIf="!isMobile" contentId="main-content" type="overlay" (mouseenter)="toggleMenu(true)" (mouseleave)="toggleMenu(false)" [ngClass]="{ menuClosed: !menuOpen, menuOpen: menuOpen }">
      <ion-content>
        <ion-list id="menu-list">
          <div class="menu-header" [ngClass]="{menuHeaderClosed: !menuOpen}">
            <ion-list-header>
              <img *ngIf="menuOpen" src="../assets/img/logo-full.svg" alt="Rockwell Client Portal"/>
              <img *ngIf="!menuOpen" src="../assets/img/logo-min.svg" alt="Rockwell Client Portal"/>
            </ion-list-header>
          </div>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [ngClass]="{menuClosedItem: !menuOpen}" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <!-- <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon> -->
              <ion-icon [src]="'assets/icon/' + p.icon + '.svg'"></ion-icon>
              <ion-label *ngIf="menuOpen">{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>

      <ion-footer>
        <ion-list id="menu-list-footer">
          <ng-container *ngIf="!isProd">
            <ion-menu-toggle auto-hide="false">
              <ion-item 
                [ngClass]="{menuClosedItem: !menuOpen}"
                routerDirection="root"
                [routerLink]="'/ui-elements'"
                lines="none"
                detail="false"
                routerLinkActive="selected">
                <ion-icon name="build-outline"></ion-icon>
                <ion-label *ngIf="menuOpen">UI elements</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ng-container>
        </ion-list>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
