import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private isMobile: boolean | null = null;

  constructor(private platform: Platform) {}

  initializePlatform(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.platform.ready().then(() => {
        this.isMobile =
          this.platform.is('android') ||
          this.platform.is('ipad') ||
          this.platform.is('iphone') ||
          this.platform.is('pwa');
        // this.platform.is('tablet') removed regarding the problem with surface pro 7
        console.log('Android', this.platform.is('android'));
        console.log('Ipad', this.platform.is('ipad'));
        console.log('Iphone', this.platform.is('iphone'));
        console.log('Tablet', this.platform.is('tablet'));
        console.log('PWA', this.platform.is('pwa'));
        console.log('Platforms', this.platform.platforms());
        resolve();
      });
    });
  }

  isMobileDevice(): boolean | null {
    return this.isMobile;
  }
}
